<template>
    <div class="footer-menu">
        <div class="container">
            <div v-if="logo" class="logo">
                <img :src="logo" alt="" />
            </div>
            <div class="row">
                <div
                    v-for="(m1, m1i) in localMenu"
                    :key="`menu-${m1i}`"
                    class="col-sm-6 col-md-4 col-lg-3"
                >
                    <div class="cs-fm-m1">
                        <div class="cs-fm-m1-title">
                            <a v-if="!m1.children || typeof m1.children != 'object' || !Object.keys(m1.children).length" :href="m1.url">{{ m1.title }}</a>
                            <span v-else>
                                {{ m1.title }}
                            </span>
                        </div>
                        <div v-if="m1.children && typeof m1.children == 'object' && Object.keys(m1.children).length" class="cs-fm-m1-children">
                            <a
                                v-for="(m2, m2i) in m1.children"
                                :key="`menu-${m1i}-${m2i}`"
                                class="cs-fm-m2-title"
                                :href="m2.url">{{ m2.title }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="contacts" class="contacts">
                <span v-html="contacts"></span>
            </div>
            <div
                v-if="icons && ((Array.isArray(icons) && icons.length) || (typeof icons == 'object' && Object.keys(icons).length))"
                class="icons"
            >
				<CSButton
					v-for="(icon, key) in localIcons" :key="key"
					:data="icon.url"
				>
					<div class="cs-image-container cs-image-ratio-1x1">
						<img :src="icon.icon" :class="{'icon':icon.isIcon}" loading="lazy" />
					</div>
				</CSButton> 
            </div>
            <div v-if="copyright" class="copyright">
                <span v-html="copyright"></span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex"

import {computed} from '../libs/common-fn';

export default {
    name: 'CSFooterMenu',
	components: {
		CSButton: () => import('./CSButton.vue')
	},
	props: {
        logo: {
            type: String,
            default: ''
        },
        contacts: {
            type: String,
            default: ''
        },
        icons: {
            type: [Object, Array],
            default: () => {
                return {}
            },
        },
        copyright: {
            type: String,
            default: ''
        },
        menu: {
            type: [Object, Array],
            default: () => {
                return {}
            },
        }
	},
	computed: {
		...computed('FooterMenu'),
		localMenu(){
			if(this.menu) return Object.values(this.menu);
			else return []
		},
		localIcons(){
			let li = {}
			for(const key in this.icons){
				if (Object.hasOwnProperty.call(this.icons, key)) {
					const icon = this.icons[key]
					let url = icon.url;
					if(typeof url == 'object') url = url.url
					if(!icon.icon && url){
						if (url.includes('facebook.com')) icon.icon = '/icons/facebook.svg';
						else if (url.includes('instagram.com')) icon.icon = '/icons/instagram.svg';
						else if (url.includes('linkedin.com')) icon.icon = '/icons/linkedin.svg';
						else if (url.includes('youtube.com') || url.includes('youtu.be')) icon.icon = '/icons/youtube.svg';
						else if (url.includes('tiktok.com')) icon.icon = '/icons/tiktok.svg';
						else if (url.includes('twitter.com') || url.includes('://x.com')) icon.icon = '/icons/twitter.svg';
						else if (url.includes('t.me/') || url.includes('telegram.org')) icon.icon = '/icons/telegram.svg';
						else if (url.includes('weixin:') || url.includes('wechat.com')) icon.icon = '/icons/wechat.svg';
						else if (url.includes('whatsapp:') || url.includes('wa.me') || url.includes('whatsapp.com')) icon.icon = '/icons/whatsapp.svg';

						if(icon.icon){
							icon.isIcon = true;
						}
					}
					li[key] = icon
				}
			}
			return li
		}
	},
	mounted() {
		if (typeof cookieStore != "undefined") {
			cookieStore.get("login").then(res => {
				if (res) {
					self.isLoggedIn = res.value
				}
			})
		}
		console.log(this.localMenu)
	}
}
</script>

<style lang="scss" scoped>
@import "../libs/common-styles.scss";
.footer-menu {
	background-color: #00274f;

	@media (max-width: 991.98px) {
		.row {
			margin-top: calc( 15px * -1);

			div[class*="col-"] {
				margin-top: 15px;
			}
		}
	}

	.logo {
		margin-bottom: 30px;

		img {
			width: 100%;
			max-width: 100px;
		}
	}
	.contacts {
		font-weight: 400;
        font-size: 16px;
		margin-top: 30px;
		span {
			color: #fff !important;

			> ::v-deep {
				* {
                    color: #fff !important;

					&:last-child {
						margin-bottom: 0px;
					}
				}
			}
		}
	}
	a {
		transition: opacity 500ms ease-out;
		text-decoration: none;
		&:hover {
			opacity: 0.7;
		}
	}
	.cs-fm-m1-title {
		font-size: 22px;
		font-weight: 500;
        overflow-wrap: anywhere;
		a {
			color: #ddd;
			&:hover {
				color: #CCD5AE;
			}
		}

		span {
			color: #ddd;
		}
	}

	.cs-fm-m1-children {
		display: flex;
		flex-direction: column;

		.cs-fm-m2-title {
			font-size: 17px;
			letter-spacing: 0.3px;
			color: rgba(255,255,255,0.8);
			margin-bottom: 15px;
			
			&:first-of-type {
				margin-top: 15px;
			}

			&:last-child {
				margin-bottom: 0px;
			}
		}
	}

	.cs-fm-m2-children {
		margin-left: 10px;
	}

	.cs-fm-m3-title {
		padding: 5px 0;
		font-size: 1rem;
		color: #6d6d6d;
		a {
			color: #6d6d6d;
		}
	}
	.copyright span {
		
		color: #ddd;
		margin-top: 30px;

		> ::v-deep {
			* {
				&:last-child {
					margin-bottom: 0px;
				}
			}
		}
	}
	.cs-image-container{
		img.icon {
		    filter: invert(100%);
		}
	}

	.icons {
		display: flex;
		justify-content: flex-start;
		margin: 20px 0;
    	flex-wrap: wrap;

		::v-deep {
			a {
				width: 35px;
				height: 35px;
				display: block;
				margin-right: 15px;
				margin-top: 15px;
				& > div {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	@media (max-width: 767.98px) {
		.row {
			margin-top: -15px;
		}

		div[class*="col-"] {
			margin-top: 15px;
		}
	}
}
</style>
